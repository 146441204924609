<template>
	<div>
		<div class="veadmin-iframe-header veadmin-iframe-header-custom-fixed">
			<span>当前位置:</span>
			资讯管理
			<span>&gt;</span>
			通知公告
			<span>&gt;</span>
			查看通知
			<button class="veadmin-iframe-button" onclick="window.location.href = '/pc/admin/notice/list';">
				<i class="el-icon-back"></i>
			</button>
			<button class="veadmin-iframe-button" onclick="location.reload()">
				<i class="el-icon-refresh-right"></i>
			</button>
		</div>
		<div class="veadmin-iframe-content veadmin-iframe-content-custom-fixed">
			<div style="margin:20px;">
				<h1>
					{{ article.title }}
					<el-tag v-if="article.status == 1" type="success">已发布</el-tag>
					<el-tag v-else-if="article.status == 0" type="warning">草稿</el-tag>
					<el-tag v-else type="info">删除状态</el-tag>
				</h1>
				<div>
					<span>发布时间：{{ article.release_time }}</span>
					<span style="margin-left:20px;">更新时间：{{ article.update_time }}</span>
				</div>
				<div style="margin:4px 0px;">
					<span>所属分类：{{ article.category.label }}</span>
					<el-tag style="margin-left:20px;" v-if="article.top != 0">置顶权重：[{{ article.top }}]</el-tag>
					<el-tag style="margin-left:20px;" v-if="article.is_focus">焦点通知</el-tag>
				</div>
				<p v-if="article.note != null">备注:{{ article.note }}</p>
				<hr />
				<img v-if="article.is_focus" :src="apiServer + article.focus_image_url" style="max-width:100%;" />
				<div v-html="article.text"></div>
			</div>
		</div>
	</div>
</template>

<script>
import { apiGetArticleById } from "@/api/pub/article.js";
import base from "@/base.js";

export default {
	data() {
		return {
			apiServer: base.apiServer,
			article: {
				title: "",
				text: "",
				top: "",
				status: "",
				is_focus: "",
				focus_image_url: "",
				release_time: "",
				category: { label: "" }
			}
		};
	},
	methods: {
		// 根据ID获取文章
		async getArticleById() {
			try {
				const response = await apiGetArticleById(this.$route.query.id);
				//console.log(response);
				if (response.data.code == 200) {
					this.article = response.data.data;
					//console.log(this.article);
				} else if (response.data.code == -100) {
					this.$confirm(response.data.message + "。将跳转至登录页面, 是否继续?", "提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "error"
					})
						.then(() => {
							//跳转到登录页面（路由跳转则滑动验证会失效）
							//this.$router.push({ path: "/login?target=" + window.location.href });
							window.location.href = "/login?target=" + window.location.href;
						})
						.catch(() => {
							this.$message({
								type: "warning",
								message: "已取消"
							});
						});
				} else {
					console.log("response>>", response);
					this.$message.error(response.data.message);
				}
			} catch (error) {
				console.log(error);
			}
		}
	},
	mounted() {
		this.getArticleById();
	}
};
</script>

<style lang="scss" scoped>
.article-form {
	margin: 10px 20px;
}
</style>
